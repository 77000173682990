/**
 * Run `func`, displaying `errorInfo` to the user after any
 * `console.error` printouts and before throwing an exception.
 *
 * This is helpful when calling `ReactDOM.render`, because when errors
 * occur, it is often not clear which component was being rendered at
 * the time. By passing an `errorInfo` array containing the component
 * and props being rendered, we are able to more easily determine what
 * is going wrong.
 *
 * @param func The main function to watch for errors from. Any calls
 * it makes to `console.error` or any exceptions it throws will result
 * in `errorInfo` being printed.
 *
 * @param errorInfo An array to display to the user in case of an error.
 * The contents are currently passed as arguments to `console.error`,
   but this is an implementation detail and should not be depended upon.
 *
 * @return The result of the call to `func`
 */
export function withErrorInfo(func: () => void, errorInfo: unknown[]) {
   const errorPrinter = console.error;
   console.error = function (...args: unknown[]) {
      errorPrinter(...args);
      errorPrinter(...errorInfo);
   };
   try {
      return func();
   } catch (e) {
      errorPrinter(...errorInfo);
      throw e;
   } finally {
      console.error = errorPrinter;
   }
}
