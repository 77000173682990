declare global {
   interface Window {
      _paq?: { push: (data: any[]) => void } | any[];
   }
}

export function piwikPush(data: any[]): void {
   if (typeof window === 'undefined') {
      return;
   }

   const _paq = (window._paq = window._paq || []);
   _paq.push(data);
}
